import React, {Component} from 'react';  
import './GoogleForm.css'

class GoogleForm extends Component {

    componentDidMount() {
        
    }

    render() {
        return (
             
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdLjN-DTtDPm1KyDYgB7dPhIA1RS3paonotW46_jLjGF4T0Xw/viewform" 
        width='100%' 
        height='100%'>

    </iframe>
        );
    }
}
 

export default GoogleForm;